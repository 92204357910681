import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import Type from "../components/typography/Type"

const IndexPage = () => {
  return (
    <Layout>
    <Container>
    <div className="flex flex-col sm:flex-row space-x-24">
    <div class="w-full lg:w-1/2">
      <Type type="h1">Gain insight with Sessions</Type>
      <Type type="p4">Undertake primary research easily with teams or individual researchers.</Type>
      <div className="my-8">
        <a href="/projects"><button className="rounded-md font-bold px-6 py-2 bg-green border border-green text-white hover:bg-green/0 hover:text-green">Start</button></a>
      </div>
    </div>
    <div class="w-full lg:w-1/2">
      <img src="https://ik.imagekit.io/pathventures/illustrations/SupportTeam_GregBlue.svg"
      alt="Sessions with actors"
      />
    </div>
  </div>
   </Container>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Sessions</title>

